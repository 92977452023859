import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

export interface CprsError {
  key: string;
  title: string;
  message: string;
  error: HttpErrorResponse;
}
@Injectable({
  providedIn: 'root',
})
export class ErrorService {
  public errors: CprsError[] = [];

  public subjects = [
    { key: 'search', subject: new BehaviorSubject<CprsError[]>([]) },
    { key: 'search_unique_record', subject: new BehaviorSubject<CprsError[]>([]) },
    { key: 'name_directory', subject: new BehaviorSubject<CprsError[]>([]) },
    { key: 'associated_record', subject: new BehaviorSubject<CprsError[]>([]) },
    { key: 'application', subject: new BehaviorSubject<CprsError[]>([]) },
    { key: 'record_editor', subject: new BehaviorSubject<CprsError[]>([]) }
  ]

  removeError(index: number): void {
    this.errors.splice(index, 1);
  }

  getErrors(key: string = 'application'): BehaviorSubject<CprsError[]> {
    return this.subjects.find(subject => subject.key === key)?.subject as BehaviorSubject<CprsError[]>;
  }

  httpError(error: HttpErrorResponse, key: string, title?: string, message?: string) {
    const subjectAlwaysExists = this.subjects.find(subject => subject.key === key || subject.key === 'application');
    if (subjectAlwaysExists) {
      const error_title = title || 'Unknown Error';
      const error_msg = message || error?.message;
      const errors = [
        {
          key,
          title: error_title,
          message: error_msg,
          error,
        }
      ];

      subjectAlwaysExists.subject.next(errors);
    }
  }

  resetErrors(key: string) {
    const subjectAlwaysExists = this.subjects.find(subject => subject.key === key || subject.key === 'application');
    if (subjectAlwaysExists) {
      subjectAlwaysExists.subject.next([]);
    }
  }
}
