import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';
import { VoyagerEntity } from '@src/app/cprs/shared/interfaces/types/types';

export class VoyagerParties extends VoyagerHelper {
    get claimants_list(): VoyagerProperty {
        return this.createVoyagerProperty('Copyright Claimant', 'claimants_list', [
            'statement_from_application', // does not exist
            'claimant_full_name',
            'claimant_limitation_statement',
            'claimant_dates',
            'claimant_transfer',
            'claimant_in_notice_name',
            'claimant_doing_business_as',
            'claimant_address'
        ]);
    }

    get authorship_on_application(): VoyagerProperty {
        return this.createVoyagerProperty('Authorship on Application', 'author_statement_list', [
            'author_full_name',
            'author_anonymous_or_pseudonymous_statement',
            'author_dates_person',
            'author_domicile_statement',
            'author_work_for_hire_statement',
            'author_citizenship_statement',
            'author_authorship_statement',
            'author_doing_business_as_statement',
            'author_photographer_name', // does not exist
            'author_statement_preregistration'
        ]);
    }

    get party_1(): VoyagerProperty {
        return {
            label: (record: ElasticSearchResult) => {
                const party_1: VoyagerEntity[] = record.get('party_1');
                if (party_1) {
                    const hasOwner = party_1.some(v => v.owner_statement_gatt_document);
                    if (hasOwner) {
                        return 'Owner';
                    }
                }
                return 'Party 1';
            },
            value: (record: ElasticSearchResult) => {
                const party_1: VoyagerEntity[] = record.get('party_1');

                if (party_1) {
                    return party_1.map(party => [party.names, party.address, party.author_statement_gatt_document, party.owner_statement_gatt_document].filter(Boolean).join(' '));
                }

                return null;
            }
        }
    }

    get party_2(): VoyagerProperty {
        return {
            label: (record: ElasticSearchResult) => {
                const party_2: VoyagerEntity[] = record.get('party_2');
                if (party_2) {
                    const hasAuthor = party_2.some(v => v.author_statement_gatt_document);

                    if (hasAuthor) {
                        return 'Author';
                    }
                }
                return 'Party 2';
            },
            value: (record: ElasticSearchResult) => {
                const party_2: VoyagerEntity[] = record.get('party_2');

                if (party_2) {
                    return party_2.map(party => [party.names, party.address, party.author_statement_gatt_document].filter(Boolean).join(' '));
                    // return party_2.map(party => party.names || party.address || party.author_statement_gatt_document).filter(Boolean).join(' ');
                }

                return null;
            }
        }
    }

    // these properties dont exist anymore: owner_name_mask_work, owner_address_mask_work, owner_statement_gatt_document
    // not sure of the purpose incontext of REgistration records
    get owner(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Owner', 'owner');
    }

    get performer(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Performer', 'performer_note');
    }

    get cast(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Cast', 'participant_note');
    }

    get credits_note(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Credits', 'credits_note');
    }

    get in_notice_name(): VoyagerProperty {
        return this.createVoyagerProperty('In Notice Name', 'claimants_list', [
            'claimant_in_notice_name',
        ]);
    }
}
