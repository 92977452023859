<div class="mb-2">
    <div class="display-7">Locate record</div>
    <div class="col-sm-12 col-md-8 flex-grow-1">
      <form class="row g-3" [formGroup]="recordEditorSearchForm" (ngSubmit)="search()" novalidate autocomplete="off">
        <div class="col-12">
          <cd-search [queryControlName]="'query'" [optionsControlName]="'keywords'" [options]="recordEditorSearchOptions"
          > </cd-search>
        </div>
      </form>
    </div>
    <hr class="mb-1">
  </div>