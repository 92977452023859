<form [formGroup]="formGroup" novalidate (ngSubmit)="onSearch(true)">
  <div class="advanced-search-row row">
    <div *ngIf="this.showOperator" class="col-12 col-lg-3 px-3">
      <cd-select
        [label]="'Operator'"
        formControlName="operator_type"
        [options]="operator_options"
        [errorMessage]="'Error State'"
      ></cd-select>
    </div>
    <div class="col-12 col-lg-3 px-3 field-heading">
      <!-- <cd-select
                [label]="'Field Heading'"
                formControlName="column_name"
                [options]="field_heading_options"
                [errorMessage]="'Error State'"
            ></cd-select> -->
      <cprs-mega-mini-menu
        [formGroup]="this.formGroup"
        [(selected)]="mmmSelection"
        (onSelect)="mmm($event)"
      ></cprs-mega-mini-menu>
    </div>
    <div class="col-12 col-lg-2 px-3">
      <cd-select
        [label]="'Search Type'"
        formControlName="type_of_query"
        [options]="search_type_options"
        [errorMessage]="'Error State'"
      ></cd-select>
    </div>
    <div class="col-12 col-lg-4 px-3">
      <div class="mt-1">&nbsp;</div>
      <cd-input
        label="Search term"
        [labelHidden]="true"
        [errorMessage]="'Search term is required'"
        formControlName="query"
        (keyup.enter)="onSearch(false)"
      ></cd-input>
    </div>
  </div>
</form>
