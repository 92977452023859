<!-- Datepicker -->
<div class="d-flex">
  <h2 class="display-4 mb-3">Filter by date</h2>
  <cd-popover [ariaLabel]="'Search'">
    <span>Not all date categories apply to each record type.</span>
  </cd-popover>
</div>

<cprs-module-date-picker
  class="d-block mb-4"
  (apply)="applyDatepicker()"
  (clear)="clearDatepicker()"
  [showControls]="true"
></cprs-module-date-picker>

<!-- Filter Results -->
<div class="d-flex">
  <h2 class="display-4 mb-3">Filter results</h2>
  <cd-popover [ariaLabel]="'Search'">
    <span
      >You can view a list of all filter types and their definitions in the
      <a href="./help" rel="noopener" target="_blank" class="cd-external-icon">Help</a>
      page.
    </span>
  </cd-popover>
</div>
<cd-button (click)="clearSelectionLists()" class="pe-3" mode="secondary" size="small">
  Clear <cd-icon [cdsIcon]="'repeat'"> </cd-icon>
</cd-button>
<cd-button (click)="toggleAccordions()" mode="secondary" size="small">
  {{ this.searchResultService.expandedState }}
  <cd-icon [cdsIcon]="expandIcon()"></cd-icon>
</cd-button>

<!-- Selection Lists -->
<div class="facet-selection-lists my-3">
  <ng-container *ngFor="let facet of available_facets">
    <!-- Extra container to allow visibility toggles -->
    <ng-container *ngIf="facet.visible">
      <cprs-module-selection-list
        [title]="facet.label"
        class="d-block mb-3"
        mode="accordion"
        [expanded]="isExpanded(facet.value)"
        [formGroup]="this.stateService.selectionLists"
        [formArray]="this.getFormArray(facet.value)"
        (selectionChange)="selectionChange($event, facet)"
      >
      </cprs-module-selection-list>
    </ng-container>
  </ng-container>
</div>
