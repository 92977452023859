import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LocalStorageService } from '@src/app/cprs/services/local-storage.service';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { KeycloakService } from 'keycloak-angular';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

// interfaces for api requests

export type CreateUserSavedRecordRequest = {
    public_records_ids?: string[];
    timestamp: string; // "2000-01-17T01:30:10.305Z"
}

export type CreateUserSavedSearch = {
    field_heading: string;
    filters_added: string[];
    number_of_results: number;
    parameters: any;
    search_term: string;
    timestamp: string;
    url: string;
    item_identifier?: string;
}

export type CreateUserSavedSearchResponse = {
    data: CreateUserSavedSearch;
    email: string;
    item_identifier: string;
    keycloak_id: string;
}

export type GetUserSavedRecords = {
    data: {
        public_records_id: string;
        timestamp: string;
    },
    email: string;
    item_identifier: string;
    keycloak_id: string;
    saving?: boolean;
}

@Injectable({
    providedIn: 'root',
})
export class UserService {
    public baseUrl: string;

    constructor(
        public http: HttpClient,
        public envService: EnvService,
        public localStorage: LocalStorageService,
        public keycloakService: KeycloakService
    ) {
        this.baseUrl = this.envService.userServiceUrl;
    }

    // api methods

    getAllUserSavedRecords(): Observable<GetUserSavedRecords[]> {
        const url = `${this.baseUrl}/GetAllUserSavedRecords/`;

        return this.http.get(url).pipe(map(resp => {
            return resp as GetUserSavedRecords[];
        }))
    }

    deleteUserSavedRecordByIds(item_ids: string[]) {
        const url = `${this.baseUrl}/DeleteUserSavedRecordByIds/`;

        return this.http.post(url, item_ids);
    }

    createUserSavedRecord(request: CreateUserSavedRecordRequest): Observable<GetUserSavedRecords[]> {
        const url = `${this.baseUrl}/CreateUserSavedRecord/`;

        return this.http.post(url, request).pipe(
            map(resp => {
                return resp as GetUserSavedRecords[]
            })
        );
    }

    // saved searches
    getAllUserSavedSearches(): Observable<CreateUserSavedSearchResponse[]> {
        const url = `${this.baseUrl}/GetAllUserSavedSearches/`;

        return this.http.get(url).pipe(map(resp => {
            return resp as CreateUserSavedSearchResponse[];
        }))
    }

    deleteUserSavedSearchByIds(item_ids: string[]) {
        const url = `${this.baseUrl}/DeleteUserSavedSearchByIds/`;

        return this.http.post(url, item_ids);
    }

    createUserSavedSearch(request: CreateUserSavedSearch): Observable<CreateUserSavedSearchResponse[]> {
        const url = `${this.baseUrl}/CreateUserSavedSearch/`;

        return this.http.post(url, request).pipe(
            map(resp => {
                return resp as CreateUserSavedSearchResponse[];
            })
        );
    }
}
