import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { StateService } from '@src/app/cprs/state/state.service';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { LocalStorageService } from '@src/app/cprs/services/local-storage.service';
import { CreateUserSavedSearch, CreateUserSavedSearchResponse, GetUserSavedRecords, UserService } from './user.service';
import { catchError, map, mergeMap } from 'rxjs/operators';
import { CprsSearch, CprsService } from '@src/app/cprs/services/cprs.service';
import { Observable, throwError } from 'rxjs';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';
import { CprsRecentSearch } from '../../recent/services/recent.service';
import { KeycloakService } from 'keycloak-angular';

export type CprsSavedSearch = {
  search_term: string;
  field_heading: string;
  filters_added: string;
  number_of_results: number;
  date_saved: any;
  url: any;
};

export type CprsSavedRecord = {
  acquisition_type: { value: string; visible: boolean };
};

@Injectable({
  providedIn: 'root',
})
export class SavedFeatureService {
  public saved_records: GetUserSavedRecords[] = [];

  public saved_searches: CreateUserSavedSearchResponse[] = [];

  public active_subscription: any;

  public isLoggedIn: boolean;

  constructor(
    public http: HttpClient,
    public stateService: StateService,
    public userService: UserService,
    public cdSearchService: CdSearchService,
    public localStorage: LocalStorageService,
    public cprsService: CprsService,
    public customAuthguardService: CustomAuthGuardService,
    public keycloakService: KeycloakService
  ) {
    this.keycloakService.isLoggedIn().then(loggedIn => {
      this.isLoggedIn = loggedIn;

      if (loggedIn) {
        this.load().subscribe();
        this.loadSearches().subscribe();
      }
    });
  }

  load() {
    return this.userService.getAllUserSavedRecords().pipe(map(saved_records => {
      this.saved_records = saved_records;
    }));
  }

  loadSearches() {
    return this.userService.getAllUserSavedSearches().pipe(map(saved_searches => {
      this.saved_searches = saved_searches;
    }))
  }

  isSaved(public_records_id: string = '') {
    return this.saved_records.some(record => record.data.public_records_id === public_records_id);
  }

  isSearchSaved(url: string) {
    return this.saved_searches.some(search => search.data.url === url);
  }

  getRecords() {
    return this.userService.getAllUserSavedRecords();
  }

  /*
    TODO: since the User Service does not return a paged result set
    we can potentially have greater than 100 saved records returning from the service
    but the /search_service/search_unique_records? endpoint can only handle 100 at a time
    we need to merge batched search service calls into an overall result set.
  */
  getElasticSearchRecords(): Observable<ElasticSearchResult[]> {
    return this.getRecords().pipe(
      mergeMap((data) => {
        const public_records_ids = data.map(d => d.data.public_records_id);
        const timestamps = data.map(d => {
          return {
            public_records_id: d.data.public_records_id,
            timestamp: d.data.timestamp
          }
        });

        return this.cdSearchService.getUniqueRecords(public_records_ids).pipe(
          map(records => {
            records.forEach(r => {
              const timestamp = timestamps.find(pri => pri.public_records_id === r.get('public_records_id'));
              if (!timestamp) {
                return;
              }

              if (r && r.saved) {
                r.saved.data.timestamp = timestamp!.timestamp;
              } else {
                r.saved = {
                  data: {
                    timestamp: timestamp!.timestamp
                  }
                } as GetUserSavedRecords;
              }
            });
            return records;
          })
        )
      })
    )
  }

  getElasticSavedRecords(): Observable<{}> {
    return this.getRecords().pipe(
      mergeMap((data) => {
        const public_records_ids = data.map(d => d.data.public_records_id);
        const timestamps = data.map(d => {
          return {
            public_records_id: d.data.public_records_id,
            timestamp: d.data.timestamp
          }
        });

        return this.cdSearchService.displayUniqueRecords(public_records_ids).pipe(
          map((records: any) => {
            records.data?.forEach((r: any) => {
              const timestamp = timestamps.find(pri => pri.public_records_id === r.get('public_records_id'));
              if (!timestamp) {
                return;
              }

              if (r && r.saved) {
                r.saved.data.timestamp = timestamp!.timestamp;
              } else {
                r.saved = {
                  data: {
                    timestamp: timestamp!.timestamp
                  }
                } as GetUserSavedRecords;
              }
            });
            return records;
          })
        )
      })
    )
  }

  saveRecords(public_records_ids: string[] | string) {
    if (typeof public_records_ids === 'string') {
      public_records_ids = [public_records_ids];
    }

    const non_duplicates = public_records_ids.filter(pri => !this.saved_records.map(r => r.data.public_records_id).includes(pri));

    const request = {
      public_records_ids: non_duplicates,
      timestamp: new Date().toISOString()
    }

    return this.userService.createUserSavedRecord(request).pipe(
      mergeMap(() => {
        return this.load();
      })
    )
  }

  removeRecords(public_records_ids: string[] | string) {
    if (typeof public_records_ids === 'string') {
      public_records_ids = [public_records_ids];
    }

    const item_ids: string[] = this.saved_records.filter(r => public_records_ids.includes(r.data.public_records_id)).map(record => record.item_identifier);

    return this.userService.deleteUserSavedRecordByIds(item_ids).pipe(
      mergeMap(() => {
        return this.load();
      }),
      catchError((err: any) => {
        this.cprsService.setLoadingSubject('saved_records', false);
        return throwError(err);
      })
    )
  }

  /// SAVE SEARCH

  getSearches() {
    return this.userService.getAllUserSavedSearches();
  }

  saveSearch(search: CprsSearch) {
    const request: CreateUserSavedSearch = {
      field_heading: search.metadata?.field_heading.map((i) => i.text).join(', ') ?? '',
      filters_added: search.metadata?.filters_added.map((i) => i.label) ?? [],
      number_of_results: search.metadata?.number_of_results ?? 0,
      parameters: {},
      search_term: search.metadata?.search_term ?? '',
      timestamp: new Date().toISOString(),
      url: search.url
    }

    return this.userService.createUserSavedSearch(request).pipe(
      mergeMap(() => {
        return this.loadSearches();
      })
    )
  }

  saveRecentSearches(recent_searches: CprsRecentSearch[]) {
    return recent_searches.forEach(recent_search => {
      const request: CreateUserSavedSearch = {
        field_heading: recent_search.field_heading['value'],
        filters_added: recent_search.filters_added['value'],
        number_of_results: recent_search.number_of_results['value'],
        parameters: {},
        search_term: recent_search.search_term['value'],
        timestamp: new Date().toISOString(),
        url: recent_search.url['value']
      }

      // if duplicate do not save
      const isSaved = this.saved_searches.find(v => v.data.search_term === request.search_term && Number(v.data.number_of_results) === request.number_of_results);
      if (isSaved) {
        return;
      }

      return this.userService.createUserSavedSearch(request).pipe(
        mergeMap(() => {
          return this.loadSearches();
        })
      ).subscribe();
    })
  }

  removeSearch(search: CprsSearch) {
    const item_id = this.saved_searches.find(item => item.data.url === search.url)?.item_identifier ?? '';

    return this.userService.deleteUserSavedSearchByIds([item_id]).pipe(
      mergeMap(() => {
        return this.loadSearches();
      })
    )
  }

  removeSearches(urls: string[]) {
    const item_ids = this.saved_searches.filter(item => urls.includes(item.data.url)).map(i => i.item_identifier);

    return this.userService.deleteUserSavedSearchByIds(item_ids).pipe(
      mergeMap(() => {
        return this.loadSearches();
      }),
      catchError((err: any) => {
        this.cprsService.setLoadingSubject('saved_searches', false);

        return throwError(err);
      })
    )
  }
}
