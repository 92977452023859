import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { RecordLink, VoyagerProperty } from '../../../services/transformer.service';
import { HyperlinkSchema, RegistrationTypes, SupplementaryRegistrationNumber } from '@src/app/cprs/shared/interfaces/types/types';
import { VoyagerHelper } from '../voyager.helper';

export class VoyagerCopyrightData extends VoyagerHelper {
    get registration_number(): VoyagerProperty {
        return {
            label: (record: ElasticSearchResult) => {
                return record.isCSN() ? 'Copyright Serial Number / Date' : 'Registration Number / Date';
            },
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.registration_number];

                if (registration_number) {
                    if (record.isCSN()) {
                        return `${registration_number.registration_number} / ${record.get('publication_year')}`;
                    } else if (record.isCancelledRegistration()) {
                        return null;
                    } else {
                        return [registration_number.registration_number, registration_number.registration_date].filter(Boolean).join(' / ');
                    }
                }

                return null;
            },
            order: 1
        }
    }

    get cancelled_registration_number(): VoyagerProperty {
        return {
            label: 'Cancelled Registration Number',
            value: (record: ElasticSearchResult) => {
                return record.registration_list[RegistrationTypes.cancelled_registration]?.registration_number_cancelled;
            },
        }
    }

    get reregistered_as(): VoyagerProperty {
        return {
            label: 'Re-registered as',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.reregistration];

                if (registration_number) {
                    return {
                        value: registration_number?.registration_number,
                        url: record.createRegistrationLink(registration_number)
                    } as RecordLink;
                }

                return null;
            }
        }
    }

    get renewal_registration(): VoyagerProperty {
        return {
            label: 'Renewal Registration for',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.renewal_registration];

                if (registration_number) {
                    return {
                        value: `${registration_number.registration_number} ${registration_number.registration_date}`,
                        url: record.createRegistrationLink(registration_number)
                    } as RecordLink;
                }

                return null;
            }
        }
    }

    get supplemented_by(): VoyagerProperty {
        return {
            label: 'Supplemented by Registration',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.supplemented_by];

                if (registration_number) {
                    return {
                        value: `${registration_number.registration_number} ${registration_number.registration_date}`,
                        url: record.createRegistrationLink(registration_number)
                    } as RecordLink;
                }

                return null;
            }
        }
    }

    get supplement_to(): VoyagerProperty {
        return {
            label: 'Supplement to',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.supplement_to];

                if (registration_number) {
                    return {
                        value: `${registration_number.registration_number} ${registration_number.registration_date}`,
                        url: record.createRegistrationLink(registration_number)
                    } as RecordLink;
                }

                return null;
            }
        }
    }

    get supplement_to_registration(): VoyagerProperty {
        return {
            label: 'Supplement to Registration',
            value: (record: ElasticSearchResult) => {
                const rss_list: SupplementaryRegistrationNumber[] = record.get('supplementary_rss_list');
                return rss_list?.map(rss => {
                    if (rss.supplementary_registration_number_hyperlinks && rss.supplementary_registration_statement) {
                        const links = record.createLink(rss.supplementary_registration_number_hyperlinks);

                        let toReplace = rss.supplementary_registration_statement;

                        if (links) {
                            if (Array.isArray(links)) {
                                links.forEach(link => {
                                    if (link && link.value && link.url) {
                                        toReplace = toReplace.replace(link.value, `<a href='${link.url}'>${link.value}</a>`)
                                    }
                                })
                            } else {
                                if (links.value && links.url) {
                                    toReplace = toReplace.replace(links.value, `<a href='${links.url}'>${links.value}</a>`)
                                }
                            }
                        }

                        return toReplace;
                    } else {
                        return rss.supplementary_registration_statement;
                    }
                });
            },
            tags: ['html']
        }
    }

    get previous_registration(): VoyagerProperty {
        return {
            label: 'Previous Registration',
            tags: ['html'],
            value: (record: ElasticSearchResult) => {
                const previous_registration_number_list: any[] = record.get('previous_registration_number_list');
                if (previous_registration_number_list && Array.isArray(previous_registration_number_list)) {
                    const mapped = previous_registration_number_list.map(previous_registration => {
                        const combined_hyperlinks: HyperlinkSchema[] = [
                            previous_registration['previous_registration_number_hyperlinks'],
                            previous_registration['previous_registration_statement_registration_hyperlinks']
                        ].filter(Boolean);

                        let formatted_hyperlinks: { id_for_search: string, token_for_search: string }[] = [];
                        combined_hyperlinks?.forEach(hyperlinks => {
                            const minLength = Math.min(hyperlinks.ids.length, hyperlinks.tokens.length);

                            for (let i = 0; i < minLength; i++) {
                                formatted_hyperlinks.push({ id_for_search: hyperlinks.ids[i] ?? '', token_for_search: hyperlinks.tokens[i] ?? '' });
                            }
                        })

                        formatted_hyperlinks.sort((a, b) => { return a.token_for_search.length - b.token_for_search.length });

                        formatted_hyperlinks = formatted_hyperlinks.filter((obj, index, self) =>
                            index === self.findIndex((t) => (
                                t.id_for_search === obj.id_for_search && t.token_for_search === obj.token_for_search
                            ))
                        );

                        return {
                            previous_registration_statement: previous_registration['previous_registration_statement'],
                            hyperlinks: formatted_hyperlinks
                        }
                    });

                    return mapped.map(data => {
                        let html: string = data.previous_registration_statement;
                        if (data.hyperlinks) {
                            data.hyperlinks.forEach(link => {
                                if (html.includes(link.token_for_search)) {
                                    const search_type = 'registration_numbers';
                                    const replaceStr = '<a href="' + record.createAdvancedSearchURL(link.id_for_search, 'AND', search_type, 'exact') + '">' + link.token_for_search + '</a>';
                                    const replaced = html.replace(link.token_for_search, replaceStr);
                                    html = replaced;
                                }
                            })
                        }

                        return html;
                    });
                } else {
                    return '';
                }
            }
        }
    }

    get type_of_work_preregistered(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Type of Work Preregistered', 'type_of_work_preregistered');
    }

    get preregistration_number_date(): VoyagerProperty {
        return {
            label: 'Preregistration Number / Date',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.registration_list[RegistrationTypes.preregistration];

                if (registration_number) {
                    return `${registration_number.preregistration_number} ${registration_number.preregistration_date}`;
                }

                return null;
            }
        }
    }

    get preregistered_as(): VoyagerProperty {
        return {
            label: 'Preregistered as',
            value: (record: ElasticSearchResult) => {
                return record.get('preregistration_as_note_registration_hyperlinks')?.map((hyperlinks: any) => {
                    return record.createLink(hyperlinks);
                });
            }
        }
    }

    get preregistration_date_of_commencement_of_creation(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Creation of Work Began', 'preregistration_date_of_commencement_of_creation');
    }

    get preregistration_date_of_anticipated_completion(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Date of Anticipated Completion', 'preregistration_date_of_anticipated_completion');
    }

    get registration_class(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Registration Class', 'registration_class', ['internal', 'no-export']);
    }

    get type_of_work(): VoyagerProperty {
        return {
            label: 'Type of Work',
            value: (record: ElasticSearchResult) => {
                return record.get('type_of_work_to_english') === 'Cancelled Registrations' ? 'Cancelled Registration' : record.get('type_of_work_to_english')
            }
        }
    }

    get service_request_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Service Request Number', 'service_request_number', ['internal']);
    }

    get recordation_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Document Number', 'recordation_number');
    }

    get document_number_display_text(): VoyagerProperty {
        return {
            label: (record: ElasticSearchResult) => {
                const recordation_number: any = record?.record;
                return recordation_number?.document_number_display_text.slice(0, -1);
            },
            value: (record: ElasticSearchResult) => {
                const recordation_number: any = record?.record;
                if (recordation_number?.document_number_display_text) {
                    return [recordation_number.recordation_number]?.map((rn: any) => {
                        const record_num: any = {
                            value: rn + ' / ' + recordation_number.document_supplement_date,
                            url: record.createAdvancedSearchURL(rn, 'AND', 'recordation_numbers', 'exact')
                        } as RecordLink;
                        return record_num;
                    });
                }
                return null;
            }
        }
    }

    get cancelled_document_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Cancelled Document Number', 'recordation_number_canceled');
    }

    get entire_copyright_document(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Entire Copyright Document', 'recordation_number_range');
    }

    get type_of_document(): VoyagerProperty {
        return {
            label: 'Type of Document',
            value: (record: ElasticSearchResult) => {
                return record.get('type_of_work') ?? 'Recorded Document';
            }
        }
    }

    get registration_number_not_verified(): VoyagerProperty {
        return {
            label: 'Registration Number Not Verified',
            value: (record: ElasticSearchResult) => {
                const registration_number = record.get('additional_registration_number_unverified') ?? [];

                return registration_number?.map((token: any) => {
                    return {
                        value: token,
                        url: record.createAdvancedSearchURL(token, 'AND', 'all_copyright_numbers', 'exact')
                    } as RecordLink;
                });
            }
        }
    }

    get acquisition_number(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Acquisitions Number', 'copyright_number_for_display');
    }

    get type_of_acquisition(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Type', 'type_of_acquisition');
    }

    get terms_of_use(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Terms of Use', 'terms_of_use');
    }

    get certificate(): VoyagerProperty {
        return {
            label: 'Certificate',
            value: (record: ElasticSearchResult) => {
                if (record.get('certificate_url')) {
                    return [{
                        value: 'View PDF',
                        url: record.get('certificate_url')
                    }]
                }
                return null;
            }
        }
    }
}
