<cprs-base-page>
  <!-- <div class="title">Search Results</div> -->
  <div class="d-flex">
    <h1 class="display-4">Search Results</h1>
    <cd-popover [ariaLabel]="'Search'">
      <span
        >Search results are not a representation of all records within the system. Only the first 50,000 most relevant
        records are displayed.</span
      >
    </cd-popover>
  </div>

  <cprs-module-search-results></cprs-module-search-results>
</cprs-base-page>
