import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { AbstractControl, UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { v4 as uuidv4 } from 'uuid';

@Component({
  selector: 'cprs-module-selection-list',
  templateUrl: './selection-list.component.html',
  styleUrls: ['./selection-list.component.scss']
})
export class SelectionListComponent implements OnInit {
  @Input() formGroup: UntypedFormGroup | undefined;
  @Input() formArray: UntypedFormArray | undefined;

  @Input() mode: 'accordion' | 'default' = 'default';
  @Input() title = 'Selection List';

  @Input() expanded = true;

  @Input() histogram: boolean = true;

  @Output() selectionChange: EventEmitter<any> = new EventEmitter();

  constructor() {
    // this.formGroup?.valueChanges.subscribe((value) => {
    //   this.selectionChange.emit(value);
    // });
    // this.formArray?.valueChanges.subscribe((value) => {
    //   this.selectionChange.emit(value);
    // });
  }

  ngOnInit(): void {
    // add a unique ID to prevent library defaults from causing issues
    this.formArray?.controls.forEach((group: UntypedFormGroup) => {
      group.addControl('id', new UntypedFormControl(uuidv4()));
    });
  }

  checkboxChange(checked: boolean, group: UntypedFormGroup) {
    this.selectionChange.emit(checked);
    group.get('selected')?.setValue(checked);
  }

  hasValue(group: AbstractControl): boolean {
    return !!group.get('histogram')?.value;
  }

  getLabelVal(val: string): string | null {
    return val === 'Cancelled Registrations' ? 'Cancelled Registration' : val;
  }

  hasAnyValue() {
    return this.formArray?.controls.map((ctrl) => ctrl.get('histogram')?.value).filter(Boolean)
      ? this.formArray?.controls.map((ctrl) => ctrl.get('histogram')?.value).filter(Boolean).length > 0
      : false;
  }
}
