import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { VoyagerProperty } from '../../../services/transformer.service';
import { VoyagerHelper } from '../voyager.helper';
import { DisplayNames, VoyagerEntity } from '@src/app/cprs/shared/interfaces/types/types';

export class VoyagerNames extends VoyagerHelper {
    formatEntity(entity: VoyagerEntity) {
        if (entity.name) {
            const name = entity.dates ? entity.name + ' ' + entity.dates : entity.name;
            return { label: name, name: entity.name };
        } else if (entity.alternate_names) {
            const name = entity.dates ? entity.alternate_names + ' ' + entity.dates : entity.alternate_names;
            return { label: name, name: entity.alternate_names };
        } else {
            throw new Error('Got a person Entity that is not with a name nor alternate_names');
        }
    }

    get photographs(): VoyagerProperty {
        return {
            label: 'Photographs',
            value: (record: ElasticSearchResult) => {
                const photoList: any[] = record.get('title_photographs');

                const photoProperties = [
                    'title_photograph_title_label',
                    'title_photograph_title'
                ]

                return photoList.map(photo => {
                    return photoProperties.map(key => {
                        return Array.isArray(photo[key]) ? photo[key].join(', ') : photo[key]
                    }).join(' ');
                })
            }
        }
    }

    get names(): VoyagerProperty {
        return {
            label: 'Names',
            value: (record: ElasticSearchResult) => {
                const names: DisplayNames = record.get('display_names');

                const filteredPersons = names?.persons?.filter((person) => person.name || person.alternate_names) ?? [];
                const filteredOrgs = names?.organizations?.filter((org) => org.name || org.alternate_names) ?? [];

                const filtered = filteredPersons?.map(person => this.formatEntity(person)).concat(
                    filteredOrgs?.map(org => this.formatEntity(org))
                )

                let namesList = [];
                namesList = filtered?.map(entity => {
                    if (Array.isArray(entity.name)) {
                        return {
                            value: entity.label,
                            url: `/name-directory/${encodeURIComponent(entity.name.join(','))}/associated-records`
                        }
                    } else {
                        return {
                            value: entity.label,
                            url: `/name-directory/${encodeURIComponent(entity.name)}/associated-records`
                        }
                    }
                });

                if (record.get('name_uncontrolled')) {
                    namesList.push(record.get('name_uncontrolled'));
                }

                return namesList;
            }
        }
    }

    get name_undifferentiated_role(): VoyagerProperty {
        return {
            label: 'Name Undifferentiated Role',
            value: (record: ElasticSearchResult) => {
                const names: DisplayNames = record.get('display_names');

                return names.persons.filter(p => p.undifferentiated_roles).map(role => role.undifferentiated_roles);
            }
        }
    }

    get main_entry_personal_name(): VoyagerProperty {
        return this.createSimpleVoyagerProperty('Main Entry Personal Name', 'main_entry_personal_name');
    }
}
