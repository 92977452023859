import { Injectable } from '@angular/core';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { RecordProperty } from '../component/cd-record/cd-record.component';
import { TransformerService, VoyagerProperty } from './transformer.service';
import { CdSearchService } from '@src/app/cprs/services/search.service';
import { CardCatalog } from '../schemas/card-catalog/card-catalog.class';
import { VoyagerRecordTypes } from '../schemas/voyager/voyager.class';
import { VoyagerRegistration } from '../schemas/voyager/types/registration.voyager';
import { VoyagerAcquisition } from '../schemas/voyager/types/acquisition.voyager';
import { VoyagerRecordation } from '../schemas/voyager/types/recordation.voyager';
import { Router } from '@angular/router';
import { OpenSeadragonTileSource } from '../component/cd-openseadragon/cd-openseadragon.component';
import { FeatureFlagService } from '../../shared/services/feature-flag/feature-flag.service';

export enum CprsRecordTypes {
    voyager = 'voyager',
    card_catalog = 'card_catalog',
    siebel = 'siebel',
    recordation = 'recordation',
    rmm_voyager = 'rmm_voyager',
}
export type RecordCertificate = {
    exists: boolean;
    url: string;
}

export type DetailedRecord = {
    title: string,
    subtitle: string,
    record: ElasticSearchResult,
    schema: RecordProperty[],
    tileSources?: OpenSeadragonTileSource[],
    maximal?: boolean;
}
@Injectable({ providedIn: 'root' })
export class DetailedRecordService {
    public MAXIMAL = 'maximal';

    constructor(
        public router: Router,
        public cdSearchService: CdSearchService,
        public transformerService: TransformerService,
        public featureFlagService: FeatureFlagService
    ) {

    }

    getSchema(record: ElasticSearchResult, system_of_origin: CprsRecordTypes, certificate?: RecordCertificate | null) {
        if (Object.values(CprsRecordTypes).includes(system_of_origin)) {
            let properties: VoyagerProperty[] = [];

            if (certificate && certificate.exists) {
                record.set('certificate_url', certificate.url);
            }

            if ([CprsRecordTypes.voyager, CprsRecordTypes.rmm_voyager, CprsRecordTypes.siebel].includes(system_of_origin)) {
                const subtype = VoyagerRecordTypes[record.get('type_of_record')];

                if (Object.values(VoyagerRecordTypes).includes(subtype)) {
                    if (subtype === VoyagerRecordTypes.acquisition) {
                        properties = new VoyagerAcquisition().schema;
                    } else if (subtype === VoyagerRecordTypes.recordation) {
                        properties = new VoyagerRecordation().schema;
                    } else {
                        properties = new VoyagerRegistration().schema;
                    }
                }
            } else if (system_of_origin === CprsRecordTypes.card_catalog) {
                const card_catalog = new CardCatalog();
                properties = card_catalog.getSchema();
            }

            return this.transformerService.create(record, properties);
        }

        return [];
    }

    async createDetailedRecord(public_records_id: string): Promise<DetailedRecord | void> {
        if (public_records_id === this.MAXIMAL) {
            const record: ElasticSearchResult | undefined = await this.cdSearchService.getFullRecord().toPromise();

            if (!record) {
                return;
            }

            return this.createMaximalRecord(record);
        }

        const record: ElasticSearchResult | undefined = await this.cdSearchService.searchUniqueRecord(public_records_id).toPromise();
        if (!record) {
            return;
        }

        let certificate: RecordCertificate | null | undefined;
        try {
            certificate = await this.cdSearchService.copyrightsPdfExists(record.get('copyright_number_for_display')).toPromise();
        } catch {
            certificate = null;
        }

        if (!record.hit) {
            this.router.navigate(['page-not-found']);
            return;
        }
        const detailed_record: DetailedRecord = {
            title: record.getTitle() ?? '',
            subtitle: record.getCprsTitle(),
            record,
            schema: [],
        }

        const system_of_origin = record.get('system_of_origin');
        detailed_record.schema = this.getSchema(record, system_of_origin, certificate);

        switch (system_of_origin) {
        case CprsRecordTypes.card_catalog: {
            const card_catalog = new CardCatalog();
            const tileSources = card_catalog.getTileSources(record.get('card_identifiers'), record.get('link_to_image_url'));

            detailed_record.tileSources = tileSources;

        break;
        }
        case CprsRecordTypes.rmm_voyager: {
            this.featureFlagService.isRMMVoyagerEnabled().subscribe(enabled => {
                if (!enabled) {
                    this.router.navigate(['/page-not-found']);
                }
            })

        break;
        }
        case CprsRecordTypes.siebel: {
            this.featureFlagService.isSiebelEnabled().subscribe(enabled => {
                if (!enabled) {
                    this.router.navigate(['/page-not-found']);
                }
            })

        break;
        }
        // No default
        }

        return detailed_record;
    }

    createMaximalRecord(record: ElasticSearchResult): DetailedRecord {
        const schema = this.getSchema(record, CprsRecordTypes.voyager);

        return {
            title: 'Maximal Record',
            subtitle: 'Maximal Record',
            record,
            schema,
            maximal: true
        }
    }
}
