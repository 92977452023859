import { Component, Input, OnInit } from '@angular/core';
import { UntypedFormControl } from '@angular/forms';
import { ActivatedRoute } from '@angular/router';
import { FeatureFlagService } from '@src/app/cprs/modules/shared/services/feature-flag/feature-flag.service';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';
import { SavedFeatureService } from '../../modules/saved/services/saved.feature.service';
import { CdSelectionService } from '../../modules/selection/services/selection.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { CprsSearch, CprsService } from '../../services/cprs.service';

@Component({
  selector: 'cprs-base-page',
  templateUrl: './base-page.component.html',
  styleUrls: ['./base-page.component.scss'],
})
export class BasePageComponent implements OnInit {
  @Input() hideSearch = false;

  public current_search: CprsSearch;

  public isSaving: boolean;

  public savingLabel: 'Saving' | 'Removing';

  public isLoggedIn: boolean;

  public public_records_id: any;
  public isDisplayShareBtn: boolean = false;
  public isDisplayShareBtnForNameDir: boolean = false;

  constructor(
    public breadcrumbService: BreadcrumbService,
    public featureFlagService: FeatureFlagService,
    public cprsService: CprsService,
    public customAuthguard: CustomAuthGuardService,
    public savedFeatureService: SavedFeatureService,
    public selectionService: CdSelectionService,
    public route: ActivatedRoute
  ) { }

  ngOnInit(): void {
    this.isDisplayShareBtn = false;
    this.customAuthguard.loggedIn().subscribe(logged => {
      this.isLoggedIn = logged;

      if (this.isDetailedRecord()) {
        this.public_records_id = this.getPublicRecordsId();
        const value = this.selectionService.getSelectionGroup('detailed_record').get('items') as UntypedFormControl;
        value.valueChanges.subscribe(() => {
          this.public_records_id = this.getPublicRecordsId();
        })
      }
    });

    this.cprsService.getCurrentSearch().subscribe((search) => {
      this.current_search = search;
    });

    this.cprsService.onFacet.subscribe(search => {
      if (search) {
        this.current_search = search;
      }
    })

    if (this.route.snapshot.url?.toString() === 'search') {
      this.isDisplayShareBtn = true;
    }
    if (this.route.snapshot.url?.toString().includes('name-directory')) {
      this.isDisplayShareBtn = false;
      this.isDisplayShareBtnForNameDir = true;
    }
  }

  showSaveSearch() {
    return this.route.snapshot.url.toString().includes('search') && !this.route.snapshot.url.toString().includes('recent-search');
  }

  saveSearch() {
    // this.savedFeatureService.saveSearch(this.current_search);
    this.isSaving = true;
    this.savingLabel = 'Saving';
    this.savedFeatureService.saveSearch(this.current_search).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  removeSearch() {
    this.isSaving = true;
    this.savingLabel = 'Removing';
    this.savedFeatureService.removeSearch(this.current_search).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  saveRecord() {
    this.isSaving = true;
    this.savingLabel = 'Saving';

    this.savedFeatureService.saveRecords(this.public_records_id).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  removeRecord() {
    this.isSaving = true;
    this.savingLabel = 'Removing';

    this.savedFeatureService.removeRecords(this.public_records_id).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  isSaved() {
    if (this.current_search) {
      return this.savedFeatureService.isSearchSaved(this.current_search.url);
    }

    return false;
  }

  isDRVSaved() {
    return this.savedFeatureService.isSaved(this.public_records_id);
  }

  getControlNumber() {
    return this.route.snapshot.params['id'];
  }

  getPublicRecordsId() {
    return this.route.snapshot.params['id'];
  }

  isDetailedRecord() {
    return this.route.snapshot.url.toString().includes('detailed-record') || this.route.snapshot.url.toString().includes('application-card');
  }
}
