<cprs-table-grid
  *appLoading
  [interface]="newTable"
  [mode]="mode"
  [selectionKey]="'recent_searches'"
  [content]="data"
  [totalElements]="totalElements"
  [showPagingDisplay]="true"
>
  <div header-left>
    <cd-button-menu title="Actions" [placement]="'bottom-start'" #actionsMenuButton>
      <li (cdMenuTextButton)="email()">Email</li>
      <li (cdMenuTextButton)="download()">Download</li>
      <ng-container *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async">
        <li *ngIf="this.savedService.isLoggedIn" (cdMenuTextButton)="saveSearches()">Save searches</li>
      </ng-container>
      <li (cdMenuTextButton)="clear()">Clear searches</li>
    </cd-button-menu>
  </div>

  <div no-content-table>Once you have performed a search, your searches from this session will be available here.</div>
</cprs-table-grid>

<div class="my-4">* This list is limited to your 100 most recent searches.</div>
