import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { RecordProperty } from '@src/app/cprs/modules/detailed-record/component/cd-record/cd-record.component';
import { DetailedRecordService } from '@src/app/cprs/modules/detailed-record/services/detailed-record.service';
import { BreadcrumbService } from '@src/app/cprs/services/breadcrumb.service';
import { RecordEditorService } from '@src/app/cprs/services/record-editor.service';

@Component({
  selector: 'app-record-editor-view-page',
  templateUrl: './record-editor-view-page.component.html',
  styleUrl: './record-editor-view-page.component.scss'
})
export class RecordEditorViewPageComponent implements OnInit {
  public isLoading: boolean = false;
  public schema: RecordProperty[];
  private id: string = '';

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    private detailedRecordService: DetailedRecordService,
    public recordEditorService: RecordEditorService,
  ) { }

  ngOnInit() {
    this.breadcrumbService.breadcrumbs = [
      {
        name: 'Home',
        link: '/',
        linkType: 'routerLink',
      },
    ];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Record Editor';

    this.getRecord();
  }

  getRecord() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
      if (this.id) {
        this.createDRV(this.id);
      }
    });
  }

  createDRV(id: string) {
    this.isLoading = true;
    this.recordEditorService.loading.next(true);
    this.detailedRecordService.createDetailedRecord(id).then((drv) => {
      if (!drv) {
        this.recordEditorService.loading.next(false);
        return;
      }

      this.recordEditorService.currentRecord = drv;
      this.schema = drv.schema;

      this.recordEditorService.loading.next(false);
    })
  }

  editRecord() {
    if (this.id) this.router.navigate([`/record-editor/edit/${this.id}`]);
  }
}
