<cprs-base-page [hideSearch]="true">
    <div class="my-4">
      <h4 class="display-4">Detail View</h4>
    </div>
  
    <app-record-editor-search></app-record-editor-search>
  
    <div *appLoading="'record_editor'" class="row" [class.isLoading]="this.isLoading">
        <div *ngIf="recordEditorService.currentRecord" class="py-1">
            <div>
                <cd-button 
                [mode]="'secondary'" 
                [size]="'small'" 
                class="pe-3"
                (btnClick)="editRecord()">
                    Edit record
                    <cd-icon class="ms-1" [cdsIcon]="'pencil'"></cd-icon>
                </cd-button>
                <!-- <cd-button 
                 [mode]="'secondary'" 
                 [size]="'small'"
                 [btnClass]="'btn-danger text-danger cd-bg-white'">
                    Delete record
                    <cd-icon [cdsIcon]="'trash'" [classes]="'text-danger'"></cd-icon>
                </cd-button> -->
            </div>
            <div class="row d-flex">
                <h6 class="display-6 col-sm-12 col-md-8 pt-md-2">
                {{ recordEditorService.currentRecord.title }}
                </h6>
                <caption class="py-0">
                    * Indicates internal only fields
                </caption>
            </div>
        </div>
    
        <div>
            <cd-record [schema]="schema"></cd-record>
        </div>
    </div>
  
  </cprs-base-page>
  