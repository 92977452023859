import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { SortByComponent } from './components/sort-by/sort-by.component';
import { DesignComponentsModule } from '@cop/design/components';
import { DesignFormsModule } from '@cop/design/forms';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FacetSidebarComponent } from './components/facet-sidebar/facet-sidebar.component';
import { SelectionModule } from '../selection/selection.module';
import { FacetChipsComponent } from './components/facet-chips/facet-chips.component';
import { ChipListComponent } from './components/chip-list/chip-list.component';
import { DatePickerComponent } from './components/date-picker/date-picker.component';
import { ActionsButtonMenuComponent } from './components/actions-button-menu/actions-button-menu.component';
import { DesignTableModule } from '@cop/design/table';
import { NgxPaginationModule } from 'ngx-pagination';
import { CprsErrorsComponent } from './components/cprs-errors/cprs-errors.component';
import { ShareComponent } from './components/share/share.component';

@NgModule({
  declarations: [
    SortByComponent,
    FacetSidebarComponent,
    FacetChipsComponent,
    ChipListComponent,
    DatePickerComponent,
    ActionsButtonMenuComponent,
    CprsErrorsComponent,
    ShareComponent
  ],
  imports: [
    CommonModule,
    DesignComponentsModule,
    DesignFormsModule,
    FormsModule,
    ReactiveFormsModule,
    SelectionModule,
    DesignTableModule,
    NgxPaginationModule,
  ],
  exports: [
    SortByComponent,
    FacetSidebarComponent,
    FacetChipsComponent,
    ChipListComponent,
    DatePickerComponent,
    ActionsButtonMenuComponent,
    CprsErrorsComponent,
    ShareComponent
  ],
})
export class SharedModule {}
