import { Component, OnInit } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { BreadcrumbService } from '@src/app/cprs/services/breadcrumb.service';
import { RecordEditorService } from '@src/app/cprs/services/record-editor.service';
import { debounceTime, distinctUntilChanged, filter, map, Observable, of, switchMap } from 'rxjs';

@Component({
  selector: 'app-record-editor-edit-page',
  templateUrl: './record-editor-edit-page.component.html',
  styleUrl: './record-editor-edit-page.component.scss'
})
export class RecordEditorEditPageComponent implements OnInit {
  public searchFormGroup: UntypedFormGroup = new UntypedFormGroup({
    fields: new UntypedFormControl(),
  });

  public source: any = {};
  private allFields: any = {};
  public displayData: any = [];
  private fieldData: any = [];
  public dataFormGroup: UntypedFormGroup;
  public title: string = '';
  private id: string = '';
  private lockedFields: string[] = [
    'control_number',
    'registration_number',
  ];

  constructor(
    private breadcrumbService: BreadcrumbService,
    private route: ActivatedRoute,
    private router: Router,
    public recordEditorService: RecordEditorService
  ) { }

  ngOnInit() {
    this.breadcrumbService.breadcrumbs = [
      {
        name: 'Home',
        link: '/',
        linkType: 'routerLink',
      },
    ];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Record Editor';

    this.getRecord();
  }

  getRecord() {
    this.route.paramMap.subscribe(params => {
      this.id = params.get('id') || '';
      if (this.id) {
        this.recordEditorService.loading.next(true);
        this.recordEditorService.getRecord(this.id).subscribe(res => {
          if (res) {
            this.source = res['record_data']['_source'];
            this.allFields = res['record_data']['strongly_typed'];
            const sourceData = Object.entries(this.source);
            const allFieldData = Object.entries(this.allFields);
            this.displayData = this.buildList(sourceData);
            this.fieldData = this.buildList(allFieldData, true);

            this.title = this.displayData.find((d: any[]) => d[0] === 'title_concatenated')?.[1] || '';

            const formControl = new UntypedFormGroup({});
            for (const data of this.displayData) {
              const key: any = data[0];
              const value: any = data[1];
              const disabled: any = data[2];
              formControl.addControl(key, new UntypedFormControl({ value, disabled }))
            }
            this.dataFormGroup = formControl;
          }
          this.recordEditorService.loading.next(false);
        })
      }
    });
  }

  buildList(arr: [string, any][], allowEmpty: boolean = false, parentKey: string = ''): [string, any, boolean][] {
    let result: [string, any, boolean][] = [];

    for (const [key, value] of arr) {
        const currentKey = parentKey ? `${parentKey} | ${key}` : key;

        if (Array.isArray(value)) {
          if (value.every(item => typeof item === 'string')) {
            const lockField = this.lockedFields.includes(currentKey);
            result.push([currentKey, value, lockField]);
          } else {
            value.forEach((item) => {
                result = result.concat(this.buildList(Object.entries(item), allowEmpty, `${currentKey}`));
            });
          }
        } else if (typeof value === 'object' && value !== null) {
            result = result.concat(this.buildList(Object.entries(value), allowEmpty, currentKey));
        } else if (value === '' && !allowEmpty) {
          continue;
        } else {
          const lockField = this.lockedFields.includes(currentKey);
          result.push([currentKey, value, lockField]);
      }
    }

    return result;
  }

  preserveOrder() { return 0 }

  searchAllFields(field: string) {
    let results: any[] = [];
    if (this.fieldData.length) {
      results = this.fieldData.filter((f: any[]) => {
        return !this.displayData.map((d: any[]) => d[0]).includes(f[0]) && !f[2]
      }).filter((f: any[]) => {
        return f[0].includes(field.toLowerCase())
      }).map((f: any[]) => f[0])
      results = results.sort((a: string, b: string) => a.localeCompare(b))
    }
    return of(results);
  }

  search = (field: Observable<string>) =>
    field.pipe(
      filter(f => f.length >= 3),
      debounceTime(200),
      distinctUntilChanged(),
      switchMap(t => this.searchAllFields(t).pipe(
        map((results) => results)
      ))
    )

  addField() {
    console.info('ADD FIELD NOT IMPLEMENTED');
  }

  removeField() {
    console.info('REMOVE FIELD NOT IMPLEMENTED');
  }

  cancel() {
    this.router.navigate([`/record-editor/view/${this.id}`]);
  }

  preview() {
    console.info('PREVIEW AND SAVE NOT IMPLEMENTED');
  }
}
