import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ButtonMenuComponent } from '@cop/design/components';
import { Page, PageImpl } from '@cop/design/services';
import { AssociatedRecordsRequest } from '@src/app/cprs/modules/shared/models/associated-records-request.model';
import { BehaviorSubject } from 'rxjs';
import { CprsDialogComponent } from '../../modules/dialogs/components/cprs-dialog/cprs-dialog.component';
import { DownloadDialogComponent } from '../../modules/dialogs/components/download-dialog/download-dialog.component';
import { DialogService } from '../../modules/dialogs/services/dialog.service';
import { CdSelectionService } from '../../modules/selection/services/selection.service';
import { CprsTableGrid } from '../../modules/table/models/cprs-table-grid.interface';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { CprsService } from '../../services/cprs.service';
import { ParametersService } from '../../services/parameters.service';
import { CdSearchService } from '../../services/search.service';
import { ElasticSearchResponse, ElasticSearchResult } from '../../shared/interfaces/elastic-search.interface';

export function toTitleCase(str: string) {
  return str.replace(/\w\S*/g, function (txt: string) {
    return txt.charAt(0).toUpperCase() + txt.slice(1).toLowerCase();
  });
}

@Component({
  selector: 'app-associated-records-page',
  templateUrl: './associated-records-page.component.html',
  styleUrls: ['./associated-records-page.component.scss'],
})
export class AssociatedRecordsPageComponent implements OnInit {
  @ViewChild('actionsMenuButton')
  actionsMenuButton!: ButtonMenuComponent;

  public id: string | null;

  public associatedRecordsTable = new CprsTableGrid('associatedRecordsTable', 'List of Associated Records', [
    {
      label: 'Full Title',
      columnKey: 'full_title',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Record Type',
      columnKey: 'type_of_record',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Date',
      columnKey: 'date',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
    {
      label: 'Copyright Number',
      columnKey: 'copyright_number',
      visibility: { grid: true, table: true, visible: true },
      visible: true,
      sortable: true,
      resizable: true,
    },
  ]);

  public mode: 'grid' | 'table' = 'table';

  public data: any[] = [];

  public serverPage = new BehaviorSubject<Page<any>>(PageImpl.empty());
  public $page = PageImpl.empty();

  constructor(
    public cprsService: CprsService,
    public cdSearchService: CdSearchService,
    public breadcrumbService: BreadcrumbService,
    public cdSelectionService: CdSelectionService,
    public dialogService: DialogService,
    public route: ActivatedRoute,
    public parameterService: ParametersService
  ) {
    this.$page.size = 25;
    this.$page.number = 0;
    this.$page.numberOfElements = 10;
    this.$page.first = true;
    this.$page.last = false;

    this.route.queryParamMap.subscribe(queryParamMap => {
      const size = Number(queryParamMap.get('records_per_page') ?? 25);
      const number = Number(queryParamMap.get('page_number') ?? 0);

      this.$page.number = number;
      this.$page.size = size;
      this.serverPage.next(this.$page);
    })

    this.serverPage.next(this.$page);
  }

  ngOnInit(): void {
    this.cdSelectionService.deselectAllByKey('associated_records');

    this.breadcrumbService.breadcrumbs = [
      { name: 'Home', link: '/', linkType: 'routerLink' },
      { name: 'Name Directory', link: '/name-directory', linkType: 'routerLink' },
    ];
    const param_id = this.route.snapshot.paramMap.get('id') ?? '';
    this.id = this.route.snapshot.paramMap.get('id') ? decodeURIComponent(param_id) : null;

    if (this.id) {
      this.breadcrumbService.currentBreadcrumbTextOnly = this.id;

      const request = new AssociatedRecordsRequest({});
      request.display_name = this.id;
      request.page_number = this.$page.number;
      request.records_per_page = this.$page.size;

      this.cprsService.loading.next(true);

      this.getAssociatedRecords(request);
    }
  }

  getAssociatedRecords(request: AssociatedRecordsRequest) {
    this.cdSearchService.associatedRecords(request).subscribe((resp: ElasticSearchResponse) => {
      this.$page.totalElements = resp.metadata.hit_count;
      this.$page.totalPages = Math.ceil(this.$page.totalElements / this.$page.size);
      this.$page.first = this.$page.number === 0;
      this.$page.last = this.$page.number + 1 === this.$page.totalPages;
      this.$page.isLoaded = true;
      this.serverPage.next(this.$page);

      this.data = resp.data.map((esr) => {
        return this.toTableData(esr);
      });

      this.cprsService.loading.next(false);
    });
  }

  email() {
    const records = this.cdSelectionService.getSelectedByKey('associated_records');

    const simple_records = records.map((r) => r.public_records_id?.value);

    this.dialogService.open(CprsDialogComponent, {
      data: { title: 'Title', records: simple_records },
    });

    this.actionsMenuButton.closeDropdown();
  }

  download() {
    const records = this.cdSelectionService.getSelectedByKey('associated_records');

    const simple_records: any[] = records.map((r) => r.simple_record.value);
    const public_records_ids: any[] = records.map((r) => r.public_records_id?.value);

    this.dialogService.open(DownloadDialogComponent, {
      data: { title: 'Title', records: simple_records, public_records_ids },
    });

    this.actionsMenuButton.closeDropdown();
  }

  pageChange($page: Page<any>) {
    this.cprsService.loading.next(true);

    this.$page = $page;

    const request = new AssociatedRecordsRequest({});
    request.display_name = this.id ?? '';
    request.page_number = this.$page.number;
    request.records_per_page = this.$page.size;

    this.parameterService.addQueryParameters({
      page_number: this.$page.number,
      records_per_page: this.$page.size
    })

    this.getAssociatedRecords(request);
  }

  toTableData(esr: ElasticSearchResult) {
    return {
      full_title: {
        value: esr.getTitle(true),
        url: esr.getHref(),
        params: {
          associatedRecords: this.id,
        },
      },
      type_of_record: {
        value: toTitleCase(esr.get('type_of_record')),
      },
      date: {
        value: esr.get('representative_date'),
      },
      copyright_number: {
        value: esr.getCopyrightNumber(),
      },
      simple_record: {
        value: esr.getSimpleRecord(esr),
      },
      public_records_id: {
        value: esr.get('public_records_id'),
      },
    };
  }
}
