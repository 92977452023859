<div class="g-0">
  <cprs-errors [key]="'search'"></cprs-errors>

  <ng-container *ngIf="this.cprsService.$page.totalElements > 0">
    <div class="d-flex justify-content-between w-75 align-items-end">
      <div *ngIf="!isLoading" class="caption">
        <div>Top search results: {{ this.cprsService.$page.totalElements | number }}</div>
        <cd-paging-display
          [pageStart]="manual.pageStart"
          [pageEnd]="manual.pageEnd"
          [total]="manual.total"
        ></cd-paging-display>
      </div>
    </div>
  </ng-container>

  <div *appLoading class="row" [class.isLoading]="this.isLoading">
    <div class="col-sm-12 col-md-8 col-lg-9">
      <div class="my-2">
        <cprs-facet-chips></cprs-facet-chips>
      </div>

      <div *ngIf="content.length > 0">
        <cprs-table-grid
          [interface]="newTable"
          [mode]="viewMode"
          pagingType="server"
          [serverPage]="this.cprsService.searchPage"
          [selectionKey]="'search_results'"
          [content]="content"
          (pageChange)="pageChange($event)"
          [showModeToggle]="true"
          [showSimplePager]="true"
          [showHeaderOnEmpty]="false"
          sortType="server"
          (sortChange)="serverSideSort($event)"
          (viewModeChange)="setViewMode($event)"
        >
          <div header-left class="header-left-container">
            <cprs-actions-button-menu></cprs-actions-button-menu>

            <cprs-sort-by class="cprs-sort-section"></cprs-sort-by>
          </div>

          <ng-template let-data #header>
            <div class="d-flex row align-items-center">
              <ng-container *ngIf="isLoggedIn">
                <div
                  *ngIf="this.featureFlagService.isSavedRecordSearchEnabled() | async"
                  class="d-flex align-items-center justify-content-end"
                >
                  <ng-container *ngIf="isSaved(data) && !data.saving.value">
                    <cd-button mode="text" size="small" (btnClick)="removeRecord(data)">
                      <cd-icon [cdsIcon]="'save'"></cd-icon> Remove
                    </cd-button>
                  </ng-container>
                  <ng-container *ngIf="!isSaved(data) && !data.saving.value">
                    <cd-button mode="text" size="small" (btnClick)="saveRecord(data)">
                      <cd-icon [cdsIcon]="'save'"></cd-icon> Save
                    </cd-button>
                  </ng-container>

                  <!-- i want a container that will only show if the current data has a boolean saving flag-->
                  <ng-container *ngIf="data.saving.value">
                    <cd-button mode="text" size="small">
                      <cd-icon [cdsIcon]="'spinner'" [classes]="'fa-spin'"></cd-icon> {{ data?.saving?.label }}
                    </cd-button>
                  </ng-container>
                </div>
              </ng-container>
            </div>
          </ng-template>
        </cprs-table-grid>
      </div>

      <ng-container *ngIf="current_search?.type !== 'error'">
        <div *ngIf="content.length <= 0">
          <div>
            <div class="my-1 display-5">
              <ng-container *ngIf="current_search"> No search results were found </ng-container>
            </div>
            <div class="display-5">Search suggestions</div>
            <ul class="my-2 mx-4 list-style">
              <li>Check the spelling of your search term.</li>
              <li>Try fewer search terms.</li>
              <li>Try removing any filters that have been applied.</li>
              <li>If you conducted an Advanced Search, check your Field Heading.</li>
            </ul>

            <div class="my-4">
              <div class="display-5">Need additional assistance?</div>
              <div class="my-1">Or visit our Help section to learn more about search features and functionality.</div>
              <div class="my-1">You can also go back to the <a href="/">Public Records System home page.</a></div>
              <div class="my-1">
                If you would like to reach out to us for questions or to provide feedback please contact
                publicrecords&#64;copyright.gov
              </div>
            </div>
          </div>
        </div>

        <app-search-relevancy-survey *ngIf="this.current_search && content.length > 0"></app-search-relevancy-survey>
      </ng-container>
      <ng-container *ngIf="current_search?.type === 'error'">
        <div class="display-5">Please try again later.</div>
        <div>
          <div class="my-4">
            <div class="display-5">Need additional assistance?</div>
            <div class="my-1">Visit our Help section to learn more about search features and functionality.</div>
            <div class="my-1">You can also go back to the <a href="/">Public Records System home page.</a></div>
            <div class="my-1">
              If you would like to reach out to us for questions or to provide feedback please contact
              publicrecords&#64;copyright.gov
            </div>
          </div>
        </div>
      </ng-container>
    </div>
    <div class="col-sm-12 col-md-4 col-lg-3">
      <cprs-module-facet-sidebar *ngIf="content.length > 0"></cprs-module-facet-sidebar>
    </div>
  </div>
</div>
