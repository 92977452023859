<cprs-base-page [hideSearch]="true">
    <div class="my-4">
      <h4 class="display-4">Detail Edit</h4>
    </div>
  
    <app-record-editor-search></app-record-editor-search>

    <div *appLoading="'record_editor'" class="row">
      <ng-container *ngIf="this.source">
        <div class="mb-4">
          <h5>Add or remove fields</h5>
          <form [formGroup]="searchFormGroup" (ngSubmit)="addField()" autocomplete="off" novalidate>
            <div class="row">
              <div class="col-md-4 mb-3">
                <cd-typeahead
                  [label]="'Add field'"
                  formControlName="fields"
                  [search]="search"
                ></cd-typeahead>
              </div>
              <div class="col-md-1 mb-3 cd-ibp-md-1">
                <cd-button [type]="'submit'">Add</cd-button>
              </div>
            </div>
          </form>
        </div>
    
        <div>
          <h5>Edit fields</h5>
          <div class="row d-flex mb-4">
              <h6 class="display-6 col-sm-12 col-md-8 pt-md-2">
                {{ this.title }}
              </h6>
              <caption class="py-0">
                  * Indicates internal only fields
              </caption>
          </div>
        </div>
  
        <div *ngIf="this.displayData.length">
          <form [formGroup]="dataFormGroup" (ngSubmit)="preview()" autocomplete="off" novalidate>
            <ng-container *ngFor="let data of this.displayData">
              <div class="row">
                <div class="col-md-10 mb-3">
                  <cd-input [label]="data[0]" [formControlName]="data[0]"></cd-input>
                </div>
                <div class="col-md-2 mb-3 cd-ibp-md-1">
                  <cd-button *ngIf="!data[2]" [mode]="'secondary'" (btnClick)="removeField()">Remove</cd-button>
                </div>
              </div>
            </ng-container>
              
            <div class="row mt-4">
              <div class="py-4">
                <cd-button [mode]="'secondary'" class="pe-3" (btnClick)="cancel()">Cancel</cd-button>
                <cd-button [mode]="'primary'" [type]="'submit'">Preview & save</cd-button>
              </div>
            </div>
          </form>
        </div>
      </ng-container>
  </div>
    
  </cprs-base-page>
  