import { Component, OnInit } from '@angular/core';
import { BreadcrumbService } from '../../../services/breadcrumb.service';
import { CprsTableGrid } from '../../../modules/table/models/cprs-table-grid.interface';
import { StateService } from '../../../state/state.service';

import { Page } from '@cop/design/services';

@Component({
  selector: 'app-record-editor-page',
  templateUrl: './record-editor-search-page.component.html',
  styleUrls: ['./record-editor-search-page.component.scss'],
})
export class RecordEditorPageComponent implements OnInit {
  public newTable = new CprsTableGrid(
    'recordEditorSearchResultsTable',
    'List of record editor search results',
    [
      {
        label: 'Full Title',
        columnKey: 'full_title',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: false,
        classes: 'col-6',
      },
      {
        label: 'Document Number',
        columnKey: 'id',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: false,
        classes: 'col-2',
      },
      {
        label: 'Registration Date',
        columnKey: 'representative_date',
        visibility: { grid: true, table: true, visible: true },
        visible: true,
        sortable: false,
        classes: 'col-2',
      },
    ],
  );

  public isLoading: boolean = false;
  public mode: 'grid' | 'table' = 'grid';
  public noResults: boolean = false;
  public results: any[] = [];
  public resultCount: number = 0;

  public viewMode: 'grid' | 'table' = 'grid';

  constructor(
    private breadcrumbService: BreadcrumbService,
    public stateService: StateService,
  ) { }

  ngOnInit() {
    this.breadcrumbService.breadcrumbs = [
      {
        name: 'Home',
        link: '/',
        linkType: 'routerLink',
      },
    ];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Record Editor';
  }

  getResultObj(resultsObj: any) {
    this.results = resultsObj.results;
    this.resultCount = resultsObj.resultCount;
    this.noResults = resultsObj.noResults;
    this.isLoading = resultsObj.isLoading;
  }

  pageChange($page: Page<any>) {
    this.stateService.pagination.get('rows_per_page')?.setValue($page.size);
    this.stateService.pagination.get('page_number')?.setValue($page.number + 1);
  }
}
