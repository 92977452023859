<cprs-base-page [hideSearch]="true">
  <div class="my-4">
    <h4 class="display-4">Record Editor</h4>
  </div>

  <app-record-editor-search (resultObj)="getResultObj($event)"></app-record-editor-search>
  <cprs-errors [key]="'record_editor'"></cprs-errors>

  <div *appLoading="'record_editor'" class="row" [class.isLoading]="this.isLoading">
    <div *ngIf="results.length > 0">
      <cprs-table-grid
        [interface]="newTable"
        [mode]="mode"
        [selectionKey]="'record_editor'"
        [content]="results"
        [totalElements]="resultCount"
        [showSelectControls]="false"
        [showHeaderOnEmpty]="false"
      >
    <div header-center class="header-left-container">
          Displaying 1 - {{resultCount}} of {{resultCount}} 
        </div>
      </cprs-table-grid>
    </div>

    <ng-container *ngIf="this.noResults">
      <div>
        <div class="my-1 display-5">
          No search results were found
        </div>
        <div class="display-5">Search suggestion</div>
        <ul class="my-2 mx-4 list-style">
          <li>Check the Copyright Number or Bib ID</li>
        </ul>
      </div>
    </ng-container>
  </div>

</cprs-base-page>
