import { Injectable } from '@angular/core';
@Injectable({ providedIn: 'root' })
export class EnvService {
  // DEFAULTs are provided here, to be replaced with environment specific .js files
  public envName = '';

  public production = false;

  public searchServiceUrl = '';

  public userServiceUrl = '';

  public remitterServiceUrl = '';

  public templatingServiceUrl = '';

  public featureFlagServiceUrl = '';

  public systemAnnouncementServiceUrl = '';

  public cprsRecordServiceUrl = '';

  public enableDebug = true;

  public sentryDsn = '';

  public defaultRecordSize = 10;

  public internal = false;

  public enableErrorDialogs = true;

  public keycloakConfig = {};
}
