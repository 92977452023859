import { Component, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { UntypedFormArray, UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { StateService } from '../../state/state.service';
import { BreadcrumbService } from '../../services/breadcrumb.service';
import { CprsSearch, CprsService } from '../../services/cprs.service';
import { ParametersService } from '../../services/parameters.service';
import { Router } from '@angular/router';
import { CdSelectionService } from '../../modules/selection/services/selection.service';
import { concat } from 'rxjs';
import { SavedFeatureService } from '../../modules/saved/services/saved.feature.service';
import { CustomAuthGuardService } from '@src/app/cprs/modules/system/guards/custom-auth-guard.service';
import { FeatureFlagService } from '../../modules/shared/services/feature-flag/feature-flag.service';
import { EnvService } from '../../modules/shared/services/env/env.service';
export interface CprsQueryInterface {
  operator_type: string;
  column_name: string;
  type_of_query: string;
  query: string;
}

@Component({
  selector: 'cprs-advanced-search',
  templateUrl: './advanced-search.component.html',
  styleUrls: ['./advanced-search.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class AdvancedSearchComponent implements OnInit {
  searchTerm = '';
  category = 'all';

  // TODO: Why do we have two of these?
  advanced_search: UntypedFormGroup | undefined;

  registration_status: UntypedFormArray;
  registration_class: UntypedFormArray;
  registration_item_type: UntypedFormArray;
  recordation_item_type: UntypedFormArray;
  system_of_origin: UntypedFormArray;

  // date_type_options = [{ text: 'Text', value: 'date' }];

  hasSearched = false;

  current_search: CprsSearch;

  public isSaving: boolean;
  public savingLabel: 'Saving' | 'Removing';
  public isLoggedIn: boolean;

  @Input() showShare = false;

  constructor(
    public cprsService: CprsService,
    // public searchResultsService: SearchResultService,
    public featureFlagService: FeatureFlagService,
    public savedFeatureService: SavedFeatureService,
    public parameterService: ParametersService,
    public stateService: StateService,
    public cdSelectionService: CdSelectionService,
    public breadcrumbService: BreadcrumbService,
    public envService: EnvService,
    public router: Router,
    public customAuthguard: CustomAuthGuardService
  ) {
    // Code moved to OnInit. TestBeds make it difficult to test any code here.
  }

  ngOnInit(): void {
    this.resetState();

    this.cprsService.$page.number = 0;
    this.cprsService.$page.size = 10;
    this.cprsService.searchPage.next(this.cprsService.$page);

    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Advanced Search';

    concat(this.cprsService.getCurrentSearch(), this.cprsService.onFacet).subscribe(search => {
      if (search) {
        this.current_search = search;
        if (this.hasSearched) {
          this.breadcrumbService.breadcrumbs = [
            { name: 'Home', link: '/', linkType: 'routerLink' },
            { name: 'Advanced Search', link: decodeURIComponent(search.url), linkType: 'href' },
          ];
          this.breadcrumbService.currentBreadcrumbTextOnly = 'Search results';
        }
      }
    });

    this.customAuthguard.loggedIn().subscribe(logged => {
      this.isLoggedIn = logged;
    });

    this.cprsService.onFacet.subscribe(search => {
      if (search) {
        this.current_search = search;
      }
    })

    this.stateService.setQueries([this.getAdvancedSearchForm()]);

    this.parameterService.deserializeParamsToState();

    const preset = this.stateService._state
      .get('queries')
      ?.value.filter((group: UntypedFormGroup) => !!group.get('query')?.value);
    if (preset && preset.length > 0) {
      this.search();
    }

    if (this.router.url === '/advanced-search') {
      this.stateService.clearSelectionLists();
      this.stateService.pagination.get('page_number')?.setValue(1);
      this.stateService.pagination.get('rows_per_page')?.setValue(10);
    }

    this.registration_status = this.stateService.selectionLists.get('registration_status') as UntypedFormArray;
    this.registration_class = this.stateService.selectionLists.get('registration_class') as UntypedFormArray;
    this.registration_item_type = this.stateService.selectionLists.get('type_of_work') as UntypedFormArray;
    this.recordation_item_type = this.stateService.selectionLists.get('recordation_item_type') as UntypedFormArray;
    this.system_of_origin = this.stateService.selectionLists.get('system_of_origin') as UntypedFormArray;
  }

  get rows() {
    return this.stateService.getQueries();
  }

  checkboxChange(checked: boolean, group: UntypedFormGroup) {
    return group.get('selected')?.setValue(checked);
  }

  // eslint-disable-next-line no-empty-function
  // getQuery() {}

  search() {
    if (this.hasSearched) {
      this.resetState();
    }

    const preset = this.stateService._state
      .get('queries')
      ?.value.filter((group: UntypedFormGroup) => !!group.get('query')?.value);
    if (preset && preset.length > 0) {
      this.cprsService.search('advanced');
      this.hasSearched = true;
    } else {
      this.stateService.actionEmitEvents.next(true);
    }
  }

  resetState() {
    this.stateService.search_form.get('query')?.setValue('');
    this.stateService.pagination.get('page_number')?.setValue(1);
    this.stateService.pagination.get('rows_per_page')?.setValue(10);
    this.stateService.clearSelectionLists();
    this.stateService.date_picker.get('start_date')?.setValue(null);
    this.stateService.date_picker.get('end_date')?.setValue(null);
    this.stateService.date_picker.get('date_type')?.setValue('representative_date');
    this.stateService._state.get('sort_field')?.setValue(null);
    this.stateService._state.get('sort_order')?.setValue('asc');
    this.cdSelectionService.deselectAllByKey('search_results');
    this.cdSelectionService.selection_groups.search_results.get('items')?.setValue([]);
    this.cprsService.advancedSearchPreformed = false;
    this.breadcrumbService.breadcrumbs = [{ name: 'Home', link: '/', linkType: 'routerLink' }];
    this.breadcrumbService.currentBreadcrumbTextOnly = 'Advanced Search';
    this.cprsService.searchPage.next({
      number: 0,
      numberOfElements: 10,
      size: 10,
      totalElements: 0,
    } as any);
  }

  clear() {
    this.clearAdvancedSearch();
    this.resetState();
    this.hasSearched = false;
    this.parameterService.serializeQueryToURL(this.parameterService.advancedSearchParameters());
  }

  clearAdvancedSearch() {
    this.stateService.setQueries([this.getAdvancedSearchForm()]);
    this.stateService.date_picker.get('date_type')?.setValue('representative_date');
    this.stateService.date_picker.get('start_date')?.setValue(null);
    this.stateService.date_picker.get('end_date')?.setValue(null);
    this.stateService.clearSelectionLists();
  }

  getAdvancedSearchForm(
    data: CprsQueryInterface = {
      operator_type: 'AND',
      column_name: 'keyword',
      type_of_query: 'phrase',
      query: '',
    } as CprsQueryInterface
  ): UntypedFormGroup {
    return new UntypedFormGroup({
      operator_type: new UntypedFormControl(data.operator_type),
      column_name: new UntypedFormControl(data.column_name),
      type_of_query: new UntypedFormControl(data.type_of_query),
      query: new UntypedFormControl(data.query),
    });
  }

  recordTypeSelected() {
    if (this.hasSearched) {
      this.search();
    }
  }

  // eslint-disable-next-line no-empty-function
  check() { }

  addRow(_event: Event) {
    if (this.rows.length > 4) {
      return;
    }
    const fg = this.getAdvancedSearchForm();

    this.stateService.addQuery(fg);
  }

  removeRow(index: number) {
    this.stateService.removeLastQuery(index);
  }

  saveSearch() {
    this.isSaving = true;
    this.savingLabel = 'Saving';

    this.savedFeatureService.saveSearch(this.current_search).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  removeSearch() {
    this.isSaving = true;
    this.savingLabel = 'Removing';

    this.savedFeatureService.removeSearch(this.current_search).subscribe(() => {
      this.isSaving = false;
    }, () => {
      this.isSaving = false;
    })
  }

  isSaved() {
    return this.savedFeatureService.isSearchSaved(this.current_search.url);
  }

  getFormArray(key: string): UntypedFormArray {
    return this.stateService.selectionLists.get(key) as UntypedFormArray;
  }
}
