import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { EnvService } from '@src/app/cprs/modules/shared/services/env/env.service';
import { LocalStorageService } from '@src/app/cprs/services/local-storage.service';
import { environment } from '@src/environments/environment';
import { KeycloakService } from 'keycloak-angular';
import { Observable, of, ReplaySubject } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { ErrorService } from './error.service';
import { DetailedRecord } from '../modules/detailed-record/services/detailed-record.service';

export interface RecordEditorSearchParams {
  keywords: string;
  query: string;
}

export class RecordEditorSearchResult {
  constructor(
    public response: any[],
  ) { }
}

@Injectable({
  providedIn: 'root',
})
export class RecordEditorService {
  private readonly baseUrl = environment.cprsRecordServiceUrl;
  public loading: ReplaySubject<boolean> = new ReplaySubject<boolean>();
  public recordEditorSearchParams: RecordEditorSearchParams;
  public recordEditorSearchResult: RecordEditorSearchResult;
  public currentRecord: DetailedRecord;

  constructor(
    private http: HttpClient,
    private envService: EnvService,
    private errorService: ErrorService,
    public localStorage: LocalStorageService,
    public keycloakService: KeycloakService
  ) {
    this.baseUrl = this.envService.cprsRecordServiceUrl;
    this.loading.next(false);
  }

  getRecord(id: string): Observable<any> {
    const url = `${this.baseUrl}/records/${id}`;

    return this.http.get(url).pipe(
      map((resp) => {
        this.errorService.resetErrors('record_editor');
        this.recordEditorSearchResult = new RecordEditorSearchResult(resp as any);
        return resp;
      }),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(
          error,
          'record_editor',
          'Error Loading Detail Result',
          'There was an error loading the detail result. Please try again later.'
        );
        return of([]);
      })
    );
  }
}
