<div *ngIf="this.formGroup && this.formArray" class="cprs-selection-list">
  <ng-container *ngIf="this.histogram && hasAnyValue()">
    <cd-accordion *ngIf="this.mode === 'accordion'" [size]="'small'" [expanded]="expanded">
      <!-- <h3 cdAccordionHeader>{{ this.title }}</h3> -->
      <div cdAccordionBody>
        <form [formGroup]="formGroup">
          <cd-fieldset [showLegend]="true" [legend]="this.title">
            <ng-container *ngFor="let group of formArray.controls">
              <div *ngIf="hasValue(group)" class="row g-0 flex-nowrap cprs-label-section">
                <div class="col-8 col-md-8 label">
                  <cd-input-crs
                    [inputId]="group.get('id')?.value"
                    [label]="getLabelVal(group.get('label')?.value) ?? ''"
                    [errorMessage]="'Error state'"
                    [formControl]="$any(group.get('selected'))"
                    (change)="checkboxChange($any($event.target).checked, $any(group))"
                  ></cd-input-crs>
                </div>
                <div class="col-4 col-md-4 d-flex justify-content-end value">
                  {{ group.get('histogram')?.value | number }}
                </div>
              </div>
            </ng-container>
          </cd-fieldset>
        </form>
      </div>
    </cd-accordion>
  </ng-container>

  <div *ngIf="this.mode === 'default'">
    <!-- <h3 class="display-5">{{ this.title }}</h3> -->
    <div class="cprs-selection">
      <form [formGroup]="formGroup">
        <cd-fieldset [showLegend]="true" [legend]="this.title">
          <ng-container *ngFor="let group of formArray.controls">
            <!-- <cd-input-crs
            [inputId]="group.get('id')?.value"
            [label]="group.get('label')?.value ?? ''"
            [errorMessage]="'Error state'"
            [formControl]="$any(group.get('selected'))"
            (change)="checkboxChange($any($event.target).checked, $any(group))"
          ></cd-input-crs> -->

            <!-- <cd-fieldset [showLegend]="false" [legend]="group.get('label')?.value ?? ''"> -->
            <ul class="list-unstyled d-flex mb-0">
              <li class="list-group-item">
                <cd-input-crs
                  [inputId]="group.get('id')?.value"
                  [label]="group.get('label')?.value ?? ''"
                  [errorMessage]="'Error state'"
                  [formControl]="$any(group.get('selected'))"
                  (change)="checkboxChange($any($event.target).checked, $any(group))"
                ></cd-input-crs>
              </li>
            </ul>
          </ng-container>
        </cd-fieldset>
      </form>
    </div>
  </div>
</div>
