<cd-modal class="cprs-disclaimer" [opened]="disclaimerShown" (openedChange)="close()">
  <div title>Disclaimer</div>
  <div body>
    <strong>PLEASE NOTE:</strong> The Copyright Public Records System (CPRS) pilot is under development and is not the
    final version of the CPRS. <br /><br />

    <p>
      This pilot does not replace or supersede the online
      <a rel="noopener noreferrer" href="https://cocatalog.loc.gov" target="_blank">public catalog</a> or existing
      search practices the Copyright Office has established. Results obtained through the CPRS pilot should not be used
      for legal purposes. Note that there may be a delay of a day or more in the results displayed in this system as
      compared to the official online catalog.
    </p>
    <p>
      The purpose of this pilot is to develop a single, easy-to-navigate, highly searchable database of U.S. Copyright
      Office public records. Please try out both the simple search interface and the advanced search interface that
      includes a query builder and filters. Your feedback on the system’s functionality and features is crucial to
      improving and enhancing the CPRS pilot.
    </p>

    <p>
      For information on searching copyright records, see
      <a rel="noopener noreferrer" href="https://www.copyright.gov/circs/circ22.pdf" target="_blank"
        >How to Investigate the Copyright Status of a Work (Circular 22)</a
      >. For information on removing personal information from Copyright Office public records, refer to
      <a rel="noopener noreferrer" href="https://www.copyright.gov/circs/circ18.pdf" target="_blank"
        >Privacy: Public Copyright Registration Records (Circular 18)</a
      >.
    </p>
  </div>
  <div footer>
    <cd-button [mode]="'primary'" (click)="close()">I Acknowledge</cd-button>
  </div>
</cd-modal>
