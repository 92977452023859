import { Injectable } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { Page, PageImpl } from '@cop/design/services';
import { ElasticSearchResult } from '@src/app/cprs/shared/interfaces/elastic-search.interface';
import { StateService } from '@src/app/cprs/state/state.service';

/*
  okay for every table, we need these configuratons
    - setting up paging
    - setting up selection (dependant on paging)
    - setting up data? (altered by paging)
    - setting up sorting?

    that way we have a plugin-able for every major "feature"

    this service provides the selection & paging view models for the table

    when the table emits page events, the service is updated and corresponding the view model can be verified after either:
      - server side paging request
      - client side paging request
*/
@Injectable({
  providedIn: 'root',
})
export class CdSelectionService {
  public selection_groups = {
    search_results: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
    }),

    recent_searches: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),

    recent_records: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),

    detailed_record: new UntypedFormGroup({
      selection: new UntypedFormControl(),
      items: new UntypedFormControl([]),
      limit: new UntypedFormControl(100),
    }),

    saved_records: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),

    saved_searches: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),

    associated_records: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),

    record_editor: new UntypedFormGroup({
      selection: new UntypedFormControl([]),
      items: new UntypedFormControl([]),
      selectAll: new UntypedFormControl(false),
      limit: new UntypedFormControl(100),
      page: new UntypedFormControl(PageImpl.empty()),
    }),
  };

  constructor(public stateService: StateService) {
    const client = this.getSelectionGroup('recent_records');
    const page: Page<any> = client.get('page')?.value;
    page.size = 10;
    page.isLoaded = true;
    client.get('page')?.setValue(page);

    const recent_searches = this.getSelectionGroup('recent_searches');
    const recent_searches_page: Page<any> = recent_searches.get('page')?.value;
    recent_searches_page.size = 10;
    recent_searches_page.isLoaded = true;
    client.get('page')?.setValue(recent_searches_page);

    const saved_records = this.getSelectionGroup('saved_records');
    const rsaved_records_page: Page<any> = saved_records.get('page')?.value;
    rsaved_records_page.size = 10;
    rsaved_records_page.totalElements = 10;
    rsaved_records_page.isLoaded = true;
    client.get('page')?.setValue(rsaved_records_page);

    const saved_searches = this.getSelectionGroup('saved_searches');
    const saved_searches_page: Page<any> = saved_searches.get('page')?.value;
    saved_searches_page.size = 10;
    saved_searches_page.totalElements = 0;
    saved_searches_page.isLoaded = true;
    client.get('page')?.setValue(saved_searches_page);

    const associated_records = this.getSelectionGroup('associated_records');
    const associated_records_page: Page<any> = associated_records.get('page')?.value;
    associated_records_page.size = 10;
    associated_records_page.isLoaded = true;
    associated_records.get('page')?.setValue(page);

    const record_editor = this.getSelectionGroup('record_editor');
    const record_editor_page: Page<any> = record_editor.get('page')?.value;
    record_editor_page.size = 10;
    record_editor_page.isLoaded = true;
    record_editor.get('page')?.setValue(page);
  }

  getSelectedByKey(key: string) {
    const group: UntypedFormGroup = this.getSelectionGroup(key) as UntypedFormGroup;

    const items: UntypedFormGroup[] = group.get('items')?.value;

    const filtered = items.filter((fg) => fg.get('selected')?.value);

    return filtered.map((f) => f.get('data')?.value);
  }

  totalSelected(key: string) {
    const group: UntypedFormGroup = this.getSelectionGroup(key) as UntypedFormGroup;

    const items: UntypedFormGroup[] = group.get('items')?.value;

    const filtered = items.filter((fg) => fg.get('selected')?.value);

    return filtered.length;
  }

  deselectAllByKey(key: string) {
    const group: UntypedFormGroup = this.getSelectionGroup(key) as UntypedFormGroup;
    const items: UntypedFormGroup[] = group.get('items')?.value;
    items.forEach((i) => i.get('selected')?.setValue(false));
    group.get('selectAll')?.setValue(false);
  }

  selectPageByKey(key: string) {
    const group: UntypedFormGroup = this.getSelectionGroup(key) as UntypedFormGroup;
    let page_number = this.stateService.pagination.get('page_number')?.value;
    let rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;

    const pageControl: Page<any> = group.get('page')?.value;
    if (pageControl) {
      page_number = pageControl.number + 1;
      rows_per_page = pageControl.size;
    }

    const startIndex = (page_number - 1) * rows_per_page + 1;
    const endIndex = page_number * rows_per_page;
    const items: UntypedFormGroup[] = group.get('items')?.value;

    const limit = group.get('limit')?.value;

    items.forEach((item) => {
      const totalSelected = this.totalSelected(key);
      const index = item.get('index')?.value;
      if (totalSelected >= limit) {
        return;
      }

      if (index >= startIndex && index <= endIndex) {
        item.get('selected')?.setValue(true);
      }
    });
  }

  numSelectedOnPage(key: string) {
    const group: UntypedFormGroup = this.getSelectionGroup(key) as UntypedFormGroup;

    let page_number = this.stateService.pagination.get('page_number')?.value;
    let rows_per_page = this.stateService.pagination.get('rows_per_page')?.value;

    const pageControl: Page<any> = group.get('page')?.value;
    if (pageControl) {
      page_number = pageControl.number + 1;
      rows_per_page = pageControl.size;
    }

    const startIndex = (page_number - 1) * rows_per_page + 1;
    const endIndex = page_number * rows_per_page;

    const items: UntypedFormGroup[] = group.get('items')?.value;
    const filtered = items.filter((fg) => {
      const index = fg.get('index')?.value;

      if (index >= startIndex && index <= endIndex) {
        return fg.get('selected')?.value;
      }

      return false;
    });

    return filtered.length;
  }

  getSelectionGroup(key: string) {
    if (this.selection_groups[key]) {
      return this.selection_groups[key];
    }
  }

  hasAcquisitions(key: string): boolean {
    return this.getSelectedByKey(key).find((record) => {
      if (record instanceof ElasticSearchResult) {
        return record && record.get('type_of_record') === 'acquisition'
      }

      return record && record?.type_of_record?.value === 'acquisition'
    });
  }
}
