<cprs-base-page>
  <div class="title">Detailed Record View</div>
  <div class="subtitle">
    <ng-container *ngIf="detailed_record">
      {{ detailed_record.subtitle }}
    </ng-container>
  </div>
  <div class="mb-6">
    Copyright Catalog
    <cprs-record-navigation-text></cprs-record-navigation-text>
  </div>

  <!-- Tab View -->
  <div *ngIf="detailed_record && staff_view">
    <cd-tabs (tabClick)="loadInternalExternal($event.id ?? undefined)">
      <cd-tab [id]="'internal'" [active]="selectedInternalExternal === 'internal'" [tabTitle]="'Internal View'">
      </cd-tab>
      <cd-tab
        *ngIf="!this.isAcquisition"
        [active]="selectedInternalExternal === 'external'"
        [id]="'external'"
        [tabTitle]="'External View'"
      >
      </cd-tab>
    </cd-tabs>
  </div>

  <cprs-record-navigation></cprs-record-navigation>

  <div *appLoading="'detailed_record'" style="min-height: 600px">
    <div *ngIf="detailed_record && detailed_record.tileSources && detailed_record.tileSources.length >= 1">
      <cd-openseadragon [tileSources]="detailed_record.tileSources"></cd-openseadragon>
    </div>
    <!-- <div
      *ngIf="detailed_record"
      class="cd-record-header display-5 d-flex justify-content-between align-items-end mb-3 pt-1"
    >
      <div class="cd-record-title">
        <strong>{{ detailed_record.title }}</strong>
      </div>
      <div class="cd-record-actions d-flex p3">
        <app-share></app-share>
        <cprs-actions-button-menu [key]="'detailed_record'"></cprs-actions-button-menu>
      </div>
    </div> -->

    <div *ngIf="detailed_record" class="border-bottom display-5 mb-3 pt-1 pb-1">
      <div class="row d-flex">
        <h5 class="col-sm-12 col-md-8 pt-md-4">
          {{ detailed_record.title }}
        </h5>
        <div class="d-flex col-sm-12 col-md-4 justify-content-end align-items-end mt-1">
          <div class="d-flex align-items-center">
            <app-share></app-share>
            <cprs-actions-button-menu [key]="'detailed_record'"></cprs-actions-button-menu>
          </div>
        </div>
      </div>
    </div>

    <div>
      <cd-record [schema]="schema"></cd-record>
    </div>
  </div>

  <cprs-record-navigation></cprs-record-navigation>
</cprs-base-page>
