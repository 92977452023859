import { Component, Input, OnDestroy, OnInit, TemplateRef } from '@angular/core';
import { Subscription } from 'rxjs/internal/Subscription';
import { CprsService } from '@src/app/cprs/services/cprs.service';
import { RecordEditorService } from '@src/app/cprs/services/record-editor.service';
import { CentralErrorService } from '@src/app/cprs/services/central.error.service';

@Component({
  selector: 'app-loading-overlay',
  templateUrl: './loading-overlay.component.html',
  styleUrls: ['./loading-overlay.component.scss'],
})
export class LoadingOverlayComponent implements OnDestroy, OnInit {
  subscriptions: Subscription[] = [];
  @Input() template: TemplateRef<any> | null = null;

  @Input() width: any;
  @Input() height: any;

  @Input() loadingKey: string;

  public loading = true;

  constructor(
    private cprsService: CprsService,
    private recordEditorService: RecordEditorService,
    public centralErrorService: CentralErrorService,
  ) {
  }

  ngOnInit() {
    if (this.loadingKey) {
      if (this.loadingKey === 'record_editor') {
        this.recordEditorService.loading.subscribe({
          next: (loading) => { this.loading = loading },
          error: () => { this.loading = false }
        })
      } else {
        const loadingSubject = this.cprsService.loading_observables.find(obs => this.loadingKey === obs.key);
        if (loadingSubject) {
          loadingSubject.subject.subscribe((loading) => {
            this.loading = loading;
          },
          (error) => {
            this.centralErrorService.addError('Cprs Service Loading', error);
            this.loading = false;
          });
        }
      }
    } else {
      this.cprsService.loading.subscribe(
        (loading) => {
          this.loading = loading;
        },
        (error) => {
          this.centralErrorService.addError('Cprs Service Loading', error);
          this.loading = false;
        }
      );
    }

    if (this.width === 0) {
      this.width = '100%';
    } else {
      this.width = this.width + 'px';
    }
  }

  ngOnDestroy() {
    if (this.subscriptions) {
      this.subscriptions.forEach((subscription) => {
        if (subscription) {
          subscription.unsubscribe();
        }
      });
    }
  }
}
