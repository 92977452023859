import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup } from '@angular/forms';
import { RecordEditorSearchParams, RecordEditorService } from '../../services/record-editor.service';
import { Router } from '@angular/router';
import { CdSearchService } from '../../services/search.service';
import { ParametersService } from '../../services/parameters.service';

@Component({
  selector: 'app-record-editor-search',
  templateUrl: './record-editor-search.component.html',
  styleUrl: './record-editor-search.component.scss'
})
export class RecordEditorSearchComponent implements OnInit {
  @Output() resultObj = new EventEmitter<{}>();

  public recordEditorSearchForm = new UntypedFormGroup({
    query: new UntypedFormControl(''),
    keywords: new UntypedFormControl(''),
  });

  readonly recordEditorSearchOptions: { text: string; value: string | null; selected?: boolean }[] = [
    { text: 'Copyright Number', value: 'copyright_number', selected: true },
    { text: 'Bib ID', value: 'control_number' },
  ];

  public results: any[] = [];
  public resultCount: number = 0;
  public noResults: boolean = false;
  public isLoading: boolean = false;

  private recordEditorSearchParams: RecordEditorSearchParams;

  constructor(
    private router: Router,
    private recordEditorService: RecordEditorService,
    private parametersService: ParametersService,
    private cdSearchService: CdSearchService,
  ) { }

  ngOnInit() {
    if (!this.recordEditorSearchForm.get('keywords')?.value) {
      this.recordEditorSearchForm.get('keywords')?.setValue('copyright_number');
    }
    this.recordEditorSearchParams = this.recordEditorService.recordEditorSearchParams;
    if (this.recordEditorSearchParams?.query) {
      this.recordEditorSearchForm.get('keywords')?.setValue(this.recordEditorSearchParams.keywords);
      this.recordEditorSearchForm.get('query')?.setValue(this.recordEditorSearchParams.query);
      if (!this.router.url.includes('/view/') && !this.router.url.includes('/edit/')) {
        this.search();
      }
    }
  }

  search() {
    if (!this.recordEditorSearchForm.get('query')?.value.trim()) return;

    if (this.router.url.includes('/view/') || this.router.url.includes('/edit/')) {
      this.router.navigate(['/record-editor'], { queryParams: {} });
    }

    const keywords = this.recordEditorSearchForm.get('keywords')?.value;
    const query = this.recordEditorSearchForm.get('query')?.value
    this.recordEditorService.recordEditorSearchParams = { keywords, query };

    this.results = [];
    this.resultCount = 0;
    this.noResults = false;
    this.isLoading = true;
    this.resultObj.emit({
      results: this.results,
      resultCount: this.resultCount,
      noResults: this.noResults,
      isLoading: this.isLoading
    });
    this.recordEditorService.loading.next(true);

    if (this.recordEditorSearchForm.get('keywords')?.value === 'copyright_number') {
      const advancedParameters = this.parametersService.advancedSearchParameters();
      advancedParameters.parent_query = [{ operator_type: 'AND', column_name: 'all_copyright_numbers', type_of_query: 'exact', query }];
      this.cdSearchService.advancedSearch(advancedParameters).subscribe(res => {
        if (res && res.data?.length) {
          this.results = res.data.map((r: any) => {
            return this.getData(r['hit'])
          });
          this.resultCount = this.results.length;
        } else {
          this.noResults = true;
        }
        this.resultObj.emit({
          results: this.results,
          resultCount: this.resultCount,
          noResults: this.noResults,
          isLoading: false
        });
        this.recordEditorService.loading.next(false);
    });
    } else {
      this.cdSearchService.searchUniqueRecord(query).subscribe(res => {
        if (res && res.record) {
          this.results = [this.getData(res.record)];
          this.resultCount = this.results.length;
        } else {
          this.noResults = true;
        }
        this.resultObj.emit({
          results: this.results,
          resultCount: this.resultCount,
          noResults: this.noResults,
          isLoading: false
        });
        this.recordEditorService.loading.next(false);
      });
    }
  }

  getData(data: any) {
    const id = data['public_records_id'];
    return {
      full_title: {
        value: data['title_concatenated'],
        url: `/record-editor/view/${id}`,
      },
      id: {
        label: 'Document Number',
        value: data['recordation_number'],
      },
      representative_date: {
        label: 'Registration Date',
        value: data['representative_date'],
      },
    };
  }
}
